// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-call-for-equity-js": () => import("./../../../src/pages/call-for-equity.js" /* webpackChunkName: "component---src-pages-call-for-equity-js" */),
  "component---src-pages-events-beyond-tropes-tokenism-and-talk-js": () => import("./../../../src/pages/events/beyond-tropes-tokenism-and-talk.js" /* webpackChunkName: "component---src-pages-events-beyond-tropes-tokenism-and-talk-js" */),
  "component---src-pages-events-beyond-tropes-tokenism-and-talk-recap-js": () => import("./../../../src/pages/events/beyond-tropes-tokenism-and-talk-recap.js" /* webpackChunkName: "component---src-pages-events-beyond-tropes-tokenism-and-talk-recap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-pocam-js": () => import("./../../../src/pages/join-pocam.js" /* webpackChunkName: "component---src-pages-join-pocam-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-update-status-js": () => import("./../../../src/pages/update-status.js" /* webpackChunkName: "component---src-pages-update-status-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-visible-and-vocal-js": () => import("./../../../src/pages/visible-and-vocal.js" /* webpackChunkName: "component---src-pages-visible-and-vocal-js" */),
  "component---src-pages-who-is-pocam-js": () => import("./../../../src/pages/who-is-pocam.js" /* webpackChunkName: "component---src-pages-who-is-pocam-js" */)
}

